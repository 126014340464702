import throttle from 'lodash.throttle';
import { walk, map } from 'react-sortable-tree';
import nodeTypes from './node-types';

const NODE_VISIBILITY_STATE = 'NODE_VISIBILITY_STATE';

export const getNodeKey = ({ node }) => `${node.type}-${node.id}`;

export const getNodeUrl = (node) => {
  let url = null;
  switch (node.type) {
    case nodeTypes.Category: {
      url = `/categories/${node.id}`;
      if (node.editable) {
        url += '/edit';
      }
      return url;
    }
    case nodeTypes.Entry: {
      url = `/entries/${node.id}`;
      if (node.editable) {
        url += '/edit';
      }
      return url;
    }
    case nodeTypes.CountryLanguage: {
      return `/country_language/${node.id}`;
    }
    default: return null;
  }
};

export const isSameNode = (a, b) => {
  if (a === b) {
    return true;
  }

  if (!a || !b) {
    return false;
  }

  return a.id === b.id && a.type === b.type;
};

export const getExpandedNodes = () => {
  let expandedNodes;
  try {
    expandedNodes = JSON.parse(localStorage.getItem(NODE_VISIBILITY_STATE));
  } catch (_) {
    expandedNodes = [];
  }

  if (!Array.isArray(expandedNodes)) {
    return [];
  }
  return expandedNodes;
};

export const setExpandedNodes = (nodes) => {
  try {
    localStorage.setItem(NODE_VISIBILITY_STATE, JSON.stringify(nodes));
  } catch (_) {} // eslint-disable-line no-empty
};

export const storeNodeVisibilityState = throttle((treeData) => {
  const expandedNodes = [];
  walk({
    treeData,
    getNodeKey,
    callback: ({ node }) => {
      if (node.expanded) {
        expandedNodes.push(getNodeKey({ node }));
      }
    },
  });
  setExpandedNodes(expandedNodes);
}, 1000, { leading: false, trailing: true });

export const updateNodeVisibilityState = (treeData) => {
  const expandedNodes = getExpandedNodes();
  return map({
    treeData,
    getNodeKey,
    ignoreCollapsed: false,
    callback: ({ node }) => {
      const expanded = expandedNodes.includes(getNodeKey({ node }));
      return {
        ...node,
        expanded,
      };
    },
  });
};
