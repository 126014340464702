import {
  getDefaultCommands,
  quoteCommand,
  codeCommand,
  checkListCommand,
} from 'react-mde/lib/js/commands';

const commandsToRemove = [
  quoteCommand,
  codeCommand,
  checkListCommand,
];

const commands = getDefaultCommands().map(group => ({
  commands: group.commands.filter(c => !commandsToRemove.includes(c)),
}));

export default commands;
