import 'bootstrap';

window.jQuery = require('jquery');
require('turbolinks').start();
require('jquery-ujs');

const reactContext = require.context('./components', true);
const ReactRailsUJS = require('react_ujs');

ReactRailsUJS.useContext(reactContext);

// Bootstraping the content tree app
require('./apps/ContentTree/bootstrap');
require('./apps/ImageGrid/bootstrap');

// Vanila js
require('./pages/index.js');
