import React from 'react';
import PropTypes from 'prop-types';
import nodeTypes from '../node-types';
import ContentTree from '../containers/ContentTree';

export default class App extends React.Component {
  static propTypes = {
    fetchContentTreeIfNeeded: PropTypes.func.isRequired,
    updateCurrentNodeSelection: PropTypes.func.isRequired,
    invalidateContentTree: PropTypes.func.isRequired,
  }

  componentDidMount() {
    this.handlePageLoad();
    document.addEventListener('turbolinks:load', this.handlePageLoad);
    document.addEventListener('turbolinks:before-render', this.handlePageUnload);
  }

  componentWillUnmount() {
    document.removeEventListener('turbolinks:load', this.handlePageLoad);
    document.removeEventListener('turbolinks:before-render', this.handlePageUnload);
  }

  handlePageLoad = () => {
    document.querySelectorAll('form').forEach((e) => {
      e.addEventListener('ajax:success', this.onFormSubmitted);
    });

    // Reload data if needed
    const { fetchContentTreeIfNeeded, updateCurrentNodeSelection } = this.props;
    fetchContentTreeIfNeeded();

    // Handle node selection on page navigate
    const routes = {
      [nodeTypes.Category]: [
        /\/categories\/(\d+)/,
        /\/categories\/(\d+)\/edit/,
      ],
      [nodeTypes.Entry]: [
        /\/entries\/(\d+)/,
        /\/entries\/(\d+)\/edit/,
      ],
      [nodeTypes.CountryLanguage]: [
        /\/country_language\/(\d+)/,
        /\/(?:categories|entries)\/new\?country_language_id=(\d+)/,
      ],
    };

    let id = null;
    const type = Object.keys(routes).find((k) => {
      const regexes = routes[k];
      const { href } = window.location;

      return !!regexes.find((regex) => {
        const matches = regex.exec(href);
        if (matches !== null) {
          id = Number(matches[1]);
        }
        return !!matches;
      });
    });

    updateCurrentNodeSelection({
      type,
      id,
    });
  }

  handlePageUnload = () => {
    document.querySelectorAll('form').forEach((e) => {
      e.removeEventListener('ajax:success', this.onFormSubmitted);
    });
  }

  onFormSubmitted = () => {
    const { invalidateContentTree } = this.props;
    invalidateContentTree();
  }

  render() {
    return <ContentTree />;
  }
}
