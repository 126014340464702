import { connect } from 'react-redux';
import ContentPicker from '../components/ContentPicker';

const mapStateToProps = (state, { countryLanguageId }) => {
  const { data } = state.contentTree;

  // Get the tree of
  let contentTree = [];
  data.some(country => country.children.some((countryLanguage) => {
    if (countryLanguage.id === countryLanguageId) {
      contentTree = [countryLanguage];
      return true;
    }
    return false;
  }));

  return {
    contentTree,
  };
};

export default connect(mapStateToProps)(ContentPicker);
