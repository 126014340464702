import { connect } from 'react-redux';
import {
  invalidateAssets,
} from '../store/actions';
import ImagePicker from '../components/ImagePicker';

const mapDispatchToProps = {
  invalidateAssets,
};

export default connect(null, mapDispatchToProps)(ImagePicker);
