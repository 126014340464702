import React from 'react';

export default () => (
  <div className="asset-item asset-item--skeleton">
    <div className="asset-item__image" />
    <div className="asset-item__name">
      <div className="asset-item__text" />
    </div>
  </div>
);
