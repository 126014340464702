import React from 'react';
import { Provider } from 'react-redux';
import store from '@js/apps/store';
import MarkdownEditorContainer from './components/MarkdownEditorContainer';

export default props => (
  <Provider store={store}>
    <MarkdownEditorContainer {...props} />
  </Provider>
);
