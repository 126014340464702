const updateButtonStatus = () => {
  const checked = document.querySelectorAll('.publishing form input:checked').length > 0;
  const submitButtons = document.querySelectorAll('.publishing form button[type=submit]');
  submitButtons.forEach((button) => {
    if (checked) {
      button.removeAttribute('disabled');
    } else {
      button.setAttribute('disabled', '');
    }
  });
};

document.addEventListener('turbolinks:load', () => {
  const form = document.querySelector('.publishing form');
  if (form) {
    form.addEventListener('change', updateButtonStatus);
  }
});
