import $ from 'jquery';
import React from 'react';
import PropTypes from 'prop-types';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginImageValidateSize from 'filepond-plugin-image-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

registerPlugin(FilePondImagePreview, FilePondPluginFileValidateType, FilePondPluginImageValidateSize);

export default class ImageUpload extends React.PureComponent {
  static propTypes = {
    minSize: PropTypes.number,
    maxSize: PropTypes.number,
    invalidateAssets: PropTypes.func.isRequired,
    lang: PropTypes.shape({
      confirm_override: PropTypes.string.isRequired,
      label_idle: PropTypes.string.isRequired,
      label_file_processing: PropTypes.string.isRequired,
      label_file_processing_complete: PropTypes.string.isRequired,
      label_file_processing_error: PropTypes.string.isRequired,
      label_button_process_item: PropTypes.string.isRequired,
      label_button_remove_item: PropTypes.string.isRequired,
      label_file_type_not_allowed: PropTypes.string.isRequired,
      label_expected_min_size: PropTypes.string.isRequired,
      label_expected_max_size: PropTypes.string.isRequired,
      label_image_size_too_small: PropTypes.string.isRequired,
      label_image_size_too_big: PropTypes.string.isRequired,
      file_validate_type_label_expected_types: PropTypes.string.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    minSize: 200,
    maxSize: 2000,
  };

  filePond = React.createRef();

  onAddFile = (error, file) => {
    if (error) {
      return;
    }
    // Kind of cheating here, but we have no otherway to get the filePond instance
    const filePond = this.filePond.current;
    const { lang } = this.props;

    // Check if the file name already exists on the server
    // and then ask user if they want to override it
    $.get('/assets/find', {
      name: file.filename,
    }).then((image) => {
      if (image !== null) {
        // eslint-disable-next-line no-alert
        if (window.confirm(lang.confirm_override.replace('__filename__', file.filename))) {
          file.setMetadata('override', true);
          filePond.processFile(file);
        } else {
          filePond.removeFile(file);
        }
      } else {
        filePond.processFile(file);
      }
    });
  }

  onProcessFile = () => {
    const { invalidateAssets } = this.props;
    invalidateAssets();
  }

  processHandler = (fieldName, file, metadata = {}, load, error, progress, abort) => {
    progress(false);
    const formData = new FormData();
    formData.append(`asset[${fieldName}]`, file, file.name);
    formData.append(`asset[${fieldName}_metadata]`, JSON.stringify(metadata));

    $.ajax({
      type: 'POST',
      url: '/assets',
      data: formData,
      contentType: false,
      processData: false,
    }).then(load)
      .catch(error);

    return {
      abort,
    };
  }

  render() {
    const { lang, minSize, maxSize } = this.props;

    return (
      <FilePond
        ref={this.filePond}
        server={{
          process: this.processHandler,
        }}
        allowMultiple
        allowRevert={false}
        instantUpload={false}
        name="image"
        onaddfile={this.onAddFile}
        onprocessfile={this.onProcessFile}
        // Validate
        acceptedFileTypes={['image/*']}
        imageValidateSizeMinWidth={minSize}
        imageValidateSizeMaxWidth={maxSize}
        imageValidateSizeMinHeight={minSize}
        imageValidateSizeMaxHeight={maxSize}
        imageValidateSizeLabelExpectedMinSize={lang.label_expected_min_size}
        imageValidateSizeLabelExpectedMaxSize={lang.label_expected_max_size}
        imageValidateSizeLabelImageSizeTooSmall={lang.label_image_size_too_small}
        imageValidateSizeLabelImageSizeTooBig={lang.label_image_size_too_big}
        // Labels
        labelIdle={lang.label_idle}
        labelFileProcessing={lang.label_file_processing}
        labelFileProcessingComplete={lang.label_file_processing_complete}
        labelFileProcessingError={lang.label_file_processing_error}
        labelButtonProcessItem={lang.label_button_process_item}
        labelButtonRemoveItem={lang.label_button_remove_item}
        labelFileTypeNotAllowed={lang.label_file_type_not_allowed}
        fileValidateTypeLabelExpectedTypes={lang.file_validate_type_label_expected_types}
        labelTapToCancel="" // We have no way to cancel the upload process, so hide the label for now.
      />
    );
  }
}
