import React from 'react';
import PropTypes from 'prop-types';
import ReactMde from 'react-mde';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { imageCommand, linkCommand } from 'react-mde/lib/js/commands';
import { insertText } from 'react-mde/lib/js/util/MarkdownUtil';
import { buildNewDraftState, getMarkdownStateFromDraftState } from 'react-mde/lib/js/util/DraftUtil';
import nodeTypes from '@js/apps/ContentTree/node-types';
import ImagePickerModal from '@js/apps/ImageGrid/components/ImagePickerModal';
import ContentPickerModal from '@js/apps/ContentTree/components/ContentPickerModal';
import iconMappings from '../supports/iconMappings';
import markdownCommands from '../supports/markdownCommands';

export const assetUrl = id => `asset://${id}`;
export const entryUrl = id => `entry://${id}`;
export const categoryUrl = id => `category://${id}`;

export default class MarkdownEditor extends React.Component {
  static propTypes = {
    markdown: PropTypes.string.isRequired,
    className: PropTypes.string,
    editable: PropTypes.bool,
    onChange: PropTypes.func,
    minEditorHeight: PropTypes.number,
    maxEditorHeight: PropTypes.number,
    countryLanguageId: PropTypes.number,
    enableInternalLink: PropTypes.bool,
    lang: PropTypes.shape({
      title: PropTypes.string.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    editable: false,
    enableInternalLink: true,
    className: '',
    onChange: null,
    countryLanguageId: null,
    minEditorHeight: 500,
    maxEditorHeight: 5000,
  };

  reactMdeRef = React.createRef();

  imageCommand = {
    ...imageCommand,
    buttonProps: {
      'aria-label': 'Insert a picture',
      title: 'Insert a picture',
    },
    execute: (state) => {
      this.markdownState = state;

      this.setState({
        isImagePickerOpen: true,
      });

      return state;
    },
  };

  entryLinkCommand = {
    ...linkCommand,
    buttonContentBuilder: ({ iconProvider }) => iconProvider('entry_link'),
    buttonProps: {
      'aria-label': 'Insert an internal link',
      title: 'Insert an internal link',
    },
    execute: (state) => {
      this.markdownState = state;

      this.setState({
        isEntryPickerOpen: true,
      });

      return state;
    },
  };

  commands = markdownCommands.map(group => ({
    commands: group.commands.map((command) => {
      if (command.buttonProps['aria-label'] === 'Insert a picture') {
        command = this.imageCommand; // eslint-disable-line no-param-reassign
      }
      return command;
    }),
  }));

  buttonContentOptions = {
    iconProvider: name => <FontAwesomeIcon icon={iconMappings[name]} />,
  }

  constructor(props) {
    super(props);
    this.state = {
      isImagePickerOpen: false,
      isEntryPickerOpen: false,
    };
    const { countryLanguageId, enableInternalLink } = props;
    if (countryLanguageId !== null && enableInternalLink) {
      const secondGroup = this.commands[1];
      secondGroup.commands.splice(1, 0, this.entryLinkCommand);
    }
  }

  closeAssetPicker = () => {
    this.setState({
      isImagePickerOpen: false,
    });
  }

  onPickAnAsset = (image) => {
    let insertedText;
    const { text, selection } = getMarkdownStateFromDraftState(this.markdownState);
    if (selection.start === selection.end) {
      insertedText = insertText(text, `![${image.name}](${assetUrl(image.id)})`, selection.end);
    } else {
      const { newText, insertionLength } = insertText(text, '![', selection.start);
      insertedText = insertText(newText, `](${assetUrl(image.id)})`, selection.end + insertionLength);
      insertedText.insertionLength += insertionLength + selection.end - selection.start;
    }

    this.reactMdeRef.current.handleTextChange(buildNewDraftState(
      this.markdownState,
      {
        text: insertedText.newText,
        selection: {
          start: selection.start,
          end: selection.start + insertedText.insertionLength,
        },
      },
    ));
    this.closeAssetPicker();
  }

  closeEntryPicker = () => {
    this.setState({
      isEntryPickerOpen: false,
    });
  }

  onPickANode = (node) => {
    const urlGenerator = node.type === nodeTypes.Category ? categoryUrl : entryUrl;
    const { text, selection } = getMarkdownStateFromDraftState(this.markdownState);
    const insertedText = insertText(text, `[${node.title}](${urlGenerator(node.id)})`, selection.end);

    this.reactMdeRef.current.handleTextChange(buildNewDraftState(
      this.markdownState,
      {
        text: insertedText.newText,
        selection: {
          start: selection.start,
          end: selection.start + insertedText.insertionLength,
        },
      },
    ));
    this.closeEntryPicker();
  }

  render() {
    const {
      editable,
      className,
      markdown,
      lang,
      onChange,
      minEditorHeight,
      maxEditorHeight,
      countryLanguageId,
    } = this.props;
    const { isImagePickerOpen, isEntryPickerOpen } = this.state;
    return (
      <React.Fragment>
        <ReactMde
          ref={this.reactMdeRef}
          className={className}
          readOnly={!editable}
          commands={this.commands}
          buttonContentOptions={this.buttonContentOptions}
          value={markdown}
          onChange={onChange}
          minEditorHeight={minEditorHeight}
          maxEditorHeight={maxEditorHeight}
        />
        <ImagePickerModal
          isOpen={isImagePickerOpen}
          onPickAnAsset={this.onPickAnAsset}
          onRequestClose={this.closeAssetPicker}
          lang={lang}
        />
        {countryLanguageId !== null
          ? (
            <ContentPickerModal
              isOpen={isEntryPickerOpen}
              onPickANode={this.onPickANode}
              onRequestClose={this.closeEntryPicker}
              title={lang.content_picker_title}
              countryLanguageId={countryLanguageId}
            />
          )
          : null
        }
      </React.Fragment>
    );
  }
}
