import { connect } from 'react-redux';
import ContentTree from '../components/ContentTree';
import {
  changeContentTree,
  toggleNodeVisibility,
  moveNode,
} from '../store/actions';

const mapStateToProps = (state) => {
  const { data, currentNode } = state.contentTree;
  return {
    contentTree: data,
    currentNode,
  };
};

const mapDispatchToProps = {
  changeContentTree,
  toggleNodeVisibility,
  moveNode,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContentTree);
