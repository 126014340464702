import React from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import debounce from 'lodash.debounce';
import MarkdownPreview from './MarkdownPreview';

export default class MarkdownPreviewContainer extends React.PureComponent {
  static propTypes = {
    markdown: PropTypes.string.isRequired,
    loadingText: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      html: null,
      loading: true,
    };

    this.fetchMarkdownPreview();
  }

  componentDidUpdate(prevProps) {
    const { markdown } = this.props;
    const { loading } = this.state;
    if (markdown === prevProps.markdown) {
      return;
    }

    if (loading === false) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        loading: true,
      });
    }

    this.fetchMarkdownPreviewDebounced();
  }

  fetchMarkdownPreview = () => {
    const { markdown } = this.props;

    $.post('/markdown/preview', {
      markdown,
    }).then((res) => {
      this.setState({
        html: res.html,
        loading: false,
      });
    });
  }

  // eslint-disable-next-line react/sort-comp
  fetchMarkdownPreviewDebounced = debounce(this.fetchMarkdownPreview, 250);

  render() {
    const { loadingText } = this.props;
    const { html, loading } = this.state;
    return (!loading
      ? <MarkdownPreview html={html} />
      : <div>{loadingText}</div>
    );
  }
}
