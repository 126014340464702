import {
  LOAD_ASSETS,
  LOADING_ASSETS,
  SET_IMAGE_COUNT,
  INVALIDATE_ASSETS,
  DELETE_ASSET,
  DELETING_ASSET,
  REVERT_DELETING_ASSET,
  UPDATE_SEARCH_TERM,
} from './actions';

const initState = {
  searchTerm: '',
  data: {},
  imageCount: 50,
  deletingIDs: [],
};

export default (state = initState, action) => {
  switch (action.type) {
    case LOAD_ASSETS: {
      const { startIndex, stopIndex, data } = action;
      const updatedData = { ...state.data };
      for (let i = startIndex; i <= stopIndex; i += 1) {
        const index = i - startIndex;
        if (index > data.length) {
          delete updatedData[i];
        } else {
          updatedData[i] = data[index];
        }
      }

      return {
        ...state,
        data: updatedData,
      };
    }

    case LOADING_ASSETS: {
      const { startIndex, stopIndex } = action;
      const loadingData = {};
      for (let i = startIndex; i <= stopIndex; i += 1) {
        loadingData[i] = 'loading';
      }
      return {
        ...state,
        data: {
          ...state.data,
          ...loadingData,
        },
      };
    }

    case DELETING_ASSET: {
      const asset = action.data;
      const { deletingIDs } = state;
      return {
        ...state,
        deletingIDs: [
          ...deletingIDs,
          asset.id,
        ],
      };
    }

    case SET_IMAGE_COUNT:
      return {
        ...state,
        imageCount: action.data,
      };

    case UPDATE_SEARCH_TERM: {
      const searchTerm = action.data;
      if (state.searchTerm === searchTerm) {
        return state;
      }
      return {
        ...state,
        data: {},
        searchTerm,
        imageCount: initState.imageCount,
      };
    }

    case INVALIDATE_ASSETS:
      return {
        ...state,
        data: {},
      };

    case DELETE_ASSET: {
      const asset = action.data;
      const { data, deletingIDs } = state;
      let index = 0;
      const updatedData = Object.keys(data).reduce((result, key) => {
        const a = data[key];
        if (a === undefined) {
          return result;
        }

        if (a.id === asset.id) {
          return result;
        }
        result[index] = a; // eslint-disable-line no-param-reassign
        index += 1;
        return result;
      }, {});

      return {
        ...state,
        data: updatedData,
        deletingIDs: deletingIDs.filter(id => id !== asset.id),
      };
    }

    case REVERT_DELETING_ASSET: {
      const asset = action.data;
      const { deletingIDs } = state;
      return {
        ...state,
        deletingIDs: deletingIDs.filter(id => id !== asset.id),
      };
    }

    default:
      return state;
  }
};
