import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import ContentPicker from '../containers/ContentPicker';

const appElement = document.getElementById('main');

const ContentPickerModal = ({
  title,
  isOpen,
  onRequestClose,
  onPickANode,
  countryLanguageId,
}) => (
  <Modal
    contentLabel={title}
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    appElement={appElement}
    closeTimeoutMS={150}
    className="container modal-content"
    overlayClassName="ReactModal__Overlay"
  >
    <div className="modal-header">
      <h5 className="modal-title">{title}</h5>
      <button type="button" className="close" aria-label="Close" onClick={onRequestClose}>
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div className="modal-body">
      <ContentPicker countryLanguageId={countryLanguageId} onPickANode={onPickANode} />
    </div>
  </Modal>
);

ContentPickerModal.propTypes = {
  isOpen: PropTypes.bool,
  countryLanguageId: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  onPickANode: PropTypes.func.isRequired,
  onRequestClose: PropTypes.func.isRequired,
};

ContentPickerModal.defaultProps = {
  isOpen: false,
};

export default React.memo(ContentPickerModal);
