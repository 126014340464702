import { connect } from 'react-redux';
import {
  invalidateAssets,
} from '../store/actions';
import ImageUpload from '../components/ImageUpload';

const mapDispatchToProps = {
  invalidateAssets,
};

export default connect(null, mapDispatchToProps)(ImageUpload);
