import React from 'react';
import PropTypes from 'prop-types';
import { AutoSizer, InfiniteLoader, Grid } from 'react-virtualized';
import ImageItem from './ImageItem';
import ImageItemSkeleton from './ImageItemSkeleton';
import SearchBox from '../containers/SearchBox';

export default class ImageGrid extends React.Component {
  static propTypes = {
    fetchAssets: PropTypes.func.isRequired,
    deleteAsset: PropTypes.func.isRequired,
    imageCount: PropTypes.number.isRequired,
    searchTerm: PropTypes.string.isRequired,
    minimumBatchSize: PropTypes.number,
    images: PropTypes.shape({}).isRequired,
    deletingImages: PropTypes.arrayOf(PropTypes.number).isRequired,
    columnCount: PropTypes.number,
    rowHeight: PropTypes.number,
    columnWidth: PropTypes.number,
    deletable: PropTypes.bool,
    onClickItem: PropTypes.func,
    lang: PropTypes.shape({
      image_in_used: PropTypes.string.isRequired,
      confirm_delete: PropTypes.string.isRequired,
      search_placeholder: PropTypes.string.isRequired,
      search_no_result: PropTypes.string.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    columnCount: 5,
    rowHeight: 242,
    columnWidth: 200,
    onClickItem: null,
    minimumBatchSize: 50,
    deletable: false,
  };

  infiniteLoaderRef = React.createRef();

  componentDidUpdate(prevProps) {
    const { searchTerm } = this.props;

    if (prevProps.searchTerm !== searchTerm) {
      this.infiniteLoaderRef.current.resetLoadMoreRowsCache(true);
    }
  }

  onSectionRendered = onRowsRendered => ({
    columnStartIndex,
    columnStopIndex,
    rowStartIndex,
    rowStopIndex,
  }) => {
    const { columnCount } = this.props;
    const startIndex = rowStartIndex * columnCount + columnStartIndex;
    const stopIndex = rowStopIndex * columnCount + columnStopIndex;

    onRowsRendered({
      startIndex,
      stopIndex,
    });
  }

  onDeleteItem = (image) => {
    const { deleteAsset, lang } = this.props;
    if (image.relation_count > 0) {
      // eslint-disable-next-line no-alert
      window.alert(lang.image_in_used);
      return;
    }

    // eslint-disable-next-line no-alert
    if (window.confirm(lang.confirm_delete.replace('__filename__', image.name))) {
      deleteAsset(image);
    }
  }

  cellRenderer = ({ columnIndex, rowIndex, style }) => {
    const {
      images,
      deletingImages,
      columnCount,
      onClickItem,
      deletable,
    } = this.props;
    const index = columnIndex + rowIndex * columnCount;
    const image = images[index];
    if (image === undefined) {
      return null;
    }

    if (typeof image !== 'object' || deletingImages.includes(image.id)) {
      return (
        <div style={style} key={`skeleton-${index}`}>
          <ImageItemSkeleton />
        </div>
      );
    }
    return (
      <div style={style} key={`${index}-${image.id}`}>
        <ImageItem image={image} onClick={onClickItem} onDelete={deletable ? this.onDeleteItem : null} />
      </div>
    );
  }

  loadMoreRows = ({ startIndex = 0, stopIndex }) => {
    const { fetchAssets } = this.props;
    fetchAssets({ startIndex, stopIndex });
  }

  isRowLoaded = ({ index }) => {
    const { images } = this.props;
    return images[index] !== undefined;
  }

  render() {
    const {
      columnCount,
      imageCount,
      columnWidth,
      rowHeight,
      minimumBatchSize,
      searchTerm,
      lang,
    } = this.props;

    const rowCount = Math.ceil(imageCount / columnCount);
    return (
      <React.Fragment>
        <SearchBox defaultSearchTerm={searchTerm} placeholder={lang.search_placeholder} />
        <div className="flex-grow-1">
          { imageCount === 0 && searchTerm
            ? <p>{lang.search_no_result}</p>
            : (
              <AutoSizer>
                {({ width, height }) => (
                  <InfiniteLoader
                    ref={this.infiniteLoaderRef}
                    isRowLoaded={this.isRowLoaded}
                    loadMoreRows={this.loadMoreRows}
                    rowCount={imageCount}
                    minimumBatchSize={minimumBatchSize}
                  >
                    {({ onRowsRendered, registerChild }) => (
                      <Grid
                        onSectionRendered={this.onSectionRendered(onRowsRendered)}
                        cellRenderer={this.cellRenderer}
                        columnCount={columnCount}
                        columnWidth={columnWidth}
                        height={height}
                        rowCount={rowCount}
                        rowHeight={rowHeight}
                        width={width}
                        ref={registerChild}
                        className="asset-manager__grid"
                      />
                    )}
                  </InfiniteLoader>
                )}
              </AutoSizer>
            )
          }
        </div>
      </React.Fragment>
    );
  }
}
