import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';
import contentTree from './ContentTree/store/reducers';
import imageGrid from './ImageGrid/store/reducers';

const rootReducer = combineReducers({
  contentTree,
  imageGrid,
});

/* eslint-disable no-underscore-dangle */
const store = createStore(
  rootReducer,
  // TODO: Remove devtool in production
  // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
  applyMiddleware(
    thunkMiddleware,
  ),
);
/* eslint-enable */

export default store;
