import $ from 'jquery';

export const LOAD_ASSETS = 'LOAD_ASSETS';
export const LOADING_ASSETS = 'LOADING_ASSETS';
export const SET_IMAGE_COUNT = 'SET_IMAGE_COUNT';
export const INVALIDATE_ASSETS = 'INVALIDATE_ASSETS';
export const DELETE_ASSET = 'DELETE_ASSET';
export const DELETING_ASSET = 'DELETING_ASSET';
export const REVERT_DELETING_ASSET = 'REVERT_DELETING_ASSET';
export const UPDATE_SEARCH_TERM = 'UPDATE_SEARCH_TERM';

export const loadAssets = ({ startIndex, stopIndex, data }) => ({
  type: LOAD_ASSETS,
  data,
  startIndex,
  stopIndex,
});

export const setLoadingAssets = ({ startIndex, stopIndex }) => ({
  type: LOADING_ASSETS,
  startIndex,
  stopIndex,
});

export const setImageCount = count => ({
  type: SET_IMAGE_COUNT,
  data: count,
});

export const invalidateAssets = () => ({
  type: INVALIDATE_ASSETS,
});

export const setAssetDeleted = data => ({
  type: DELETE_ASSET,
  data,
});

export const setDeletingAsset = data => ({
  type: DELETING_ASSET,
  data,
});

export const revertDeletingAsset = data => ({
  type: REVERT_DELETING_ASSET,
  data,
});

export const updateSearchTerm = data => ({
  type: UPDATE_SEARCH_TERM,
  data,
});

export const fetchAssets = ({ startIndex, stopIndex }) => (dispatch, getState) => {
  const { searchTerm } = getState().imageGrid;
  dispatch(setLoadingAssets({ startIndex, stopIndex }));

  return $.get('/assets.json', {
    offset: startIndex,
    limit: stopIndex - startIndex + 1,
    q: searchTerm || undefined,
  }).then((data, _, jqXHR) => {
    dispatch(setImageCount(parseInt(jqXHR.getResponseHeader('X-Total-Count'), 10)));
    dispatch(loadAssets({ startIndex, stopIndex, data }));
  });
};

export const deleteAsset = asset => (dispatch) => {
  dispatch(setDeletingAsset(asset));
  return $.ajax({
    url: `/assets/${asset.id}`,
    type: 'DELETE',
  }).then((_, __, jqXHR) => {
    dispatch(setImageCount(parseInt(jqXHR.getResponseHeader('X-Total-Count'), 10)));
    dispatch(setAssetDeleted(asset));
  }).catch(() => {
    dispatch(revertDeletingAsset(asset));
  });
};
