import $ from 'jquery';

const updateEntryTypeVisibility = () => {
  const $all = $('.product-type, .video-type');
  $all.hide();

  switch ($('[name="entry[entry_type]"]').val()) {
    case 'content': {
      $all.hide();
      break;
    }
    case 'product': {
      $('.product-type').show();
      break;
    }
    case 'video': {
      $('.video-type').show();
      break;
    }
    default: break;
  }
};

document.addEventListener('turbolinks:load', () => {
  updateEntryTypeVisibility();

  $('[name="entry[entry_type]"]').change(() => {
    updateEntryTypeVisibility();
  });
});
