import $ from 'jquery';
import { toggleExpandedForAll } from 'react-sortable-tree';
import { updateNodeVisibilityState, storeNodeVisibilityState } from '../helpers';
import nodeTypes from '../node-types';

export const LOAD_CONTENT_TREE_DATA = 'LOAD_CONTENT_TREE_DATA';
export const INVALIDATE_CONTENT_TREE_DATA = 'INVALIDATE_CONTENT_TREE_DATA';
export const UPDATE_CONTENT_TREE_DATA = 'UPDATE_CONTENT_TREE_DATA';
export const CHANGE_CONTENT_TREE_DATA = 'CHANGE_CONTENT_TREE_DATA';
export const UPDATE_CURRENT_NODE_SELECTION = 'UPDATE_CURRENT_NODE_SELECTION';
export const TOGGLE_NODE_VISIBILITY = 'TOGGLE_NODE_VISIBILITY';

export const invalidateContentTree = () => ({
  type: INVALIDATE_CONTENT_TREE_DATA,
});

export const changeContentTree = data => ({
  type: CHANGE_CONTENT_TREE_DATA,
  data,
});

export const updateContentTree = data => ({
  type: UPDATE_CONTENT_TREE_DATA,
  data,
});

export const updateCurrentNodeSelection = data => ({
  type: UPDATE_CURRENT_NODE_SELECTION,
  data,
});

export const loadContentTree = data => ({
  type: LOAD_CONTENT_TREE_DATA,
  data,
});

export const toggleNodeVisibility = data => (dispatch, getState) => {
  dispatch({
    type: TOGGLE_NODE_VISIBILITY,
    data,
  });

  storeNodeVisibilityState(getState().contentTree.data);
};

const fetchContentTree = () => dispatch => $.ajax({
  url: '/content-tree',
  dataType: 'json',
}).then(response => dispatch(loadContentTree(updateNodeVisibilityState(response))));

const olderThan5Minutes = date => (date - Date.now()) / 1000 > 300;

const shouldFetchContentTree = ({ contentTree }) => {
  const { didInvalidate, lastRequest } = contentTree;
  return didInvalidate || olderThan5Minutes(lastRequest);
};

export const fetchContentTreeIfNeeded = () => (dispatch, getState) => {
  if (shouldFetchContentTree(getState())) {
    return dispatch(fetchContentTree());
  }
  return false;
};

export const expandContentTree = () => (dispatch, getState) => {
  dispatch(loadContentTree(toggleExpandedForAll({ treeData: getState().contentTree.data })));
};

export const moveNode = ({
  node,
  nodeAbove,
  nodeBelow,
  toParent,
}) => (dispatch) => {
  let url = '/content-tree/entries';
  if (node.type === nodeTypes.Category) {
    url = '/content-tree/categories';
  }

  return $.ajax({
    method: 'PUT',
    url: `${url}/${node.id}`,
    data: {
      move_before_id: nodeAbove ? nodeAbove.id : null,
      move_after_id: nodeBelow ? nodeBelow.id : null,
      to_parent_id: toParent.id,
      to_parent_type: toParent.type,
    },
  })
    .then(() => dispatch(fetchContentTree()))
    .catch(() => dispatch(fetchContentTree()));
};
