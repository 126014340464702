import React from 'react';
import PropTypes from 'prop-types';
import MarkdownPreviewContainer from './MarkdownPreviewContainer';
import MarkdownEditor from './MarkdownEditor';

const EDIT = 'EDIT';
const PREVIEW = 'PREVIEW';

export default class MarkdownEditorContainer extends React.Component {
  static propTypes = {
    content: PropTypes.string.isRequired,
    inputName: PropTypes.string,
    inputId: PropTypes.string,
    lang: PropTypes.shape({
      preview: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
      loading_text: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    inputName: null,
    inputId: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      mode: EDIT,
      markdown: props.content,
    };
  }

  togglePreview = () => {
    this.setState(state => ({
      mode: state.mode === EDIT ? PREVIEW : EDIT,
    }));
  };

  handleMarkdownChanged = (markdown) => {
    this.setState({
      markdown,
    });
  }

  isPreview = () => {
    const { mode } = this.state;
    return mode === PREVIEW;
  }

  render() {
    const {
      inputName,
      inputId,
      lang,
      ...props
    } = this.props;
    const { markdown } = this.state;
    return (
      <div className="markdown-editor">
        <input name={inputName} id={inputId} type="hidden" value={markdown} />
        <MarkdownEditor
          markdown={markdown}
          className={this.isPreview() ? 'd-none' : ''}
          onChange={this.handleMarkdownChanged}
          lang={lang}
          {...props}
        />
        <div className={!this.isPreview() ? 'd-none' : null}>
          <div className="card markdown-editor__preview">
            <div className="card-header" />
            <div className="card-body">
              <MarkdownPreviewContainer markdown={markdown} loadingText={lang.loading_text} />
            </div>
          </div>
        </div>

        <button
          className="markdown-editor__btn-preview btn btn-primary"
          type="button"
          onClick={this.togglePreview}
        >
          { this.isPreview()
            ? lang.code
            : lang.preview
          }
        </button>
      </div>
    );
  }
}
