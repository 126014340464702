import React from 'react';
import PropTypes from 'prop-types';
import SortableTree, { walk } from 'react-sortable-tree';
import 'react-sortable-tree/style.css';

import FileExplorerTheme from '../content-tree-theme';
import {
  getNodeKey,
  getNodeUrl,
  isSameNode,
} from '../helpers';
import { NodeTypes, NodeShape } from '../node-props';
import nodeTypes from '../node-types';


export default class ContentTree extends React.Component {
  static propTypes = {
    contentTree: PropTypes.arrayOf(NodeShape).isRequired,
    currentNode: PropTypes.shape({
      type: NodeTypes,
      id: PropTypes.number,
    }).isRequired,

    // Actions
    changeContentTree: PropTypes.func.isRequired,
    toggleNodeVisibility: PropTypes.func.isRequired,
    moveNode: PropTypes.func.isRequired,
  };

  static canNodeHaveChildren(node) {
    return node.type === nodeTypes.CountryLanguage || node.type === nodeTypes.Category;
  }

  static canDrag({ node }) {
    return node.editable;
  }

  sortableTreeRef = React.createRef();

  getRelativeNodes = ({ nextTreeIndex, nextParent }) => {
    const rows = this.sortableTreeRef.current.child.getRows(this.sortableTreeRef.current.child.state.draggingTreeData);
    const rowAbove = rows[nextTreeIndex - 1];
    const rowBelow = rows[nextTreeIndex];

    let nodeAbove = null;
    let nodeBelow = null;

    if (rowAbove && isSameNode(rowAbove.parentNode, nextParent)) {
      nodeAbove = rowAbove.node;
    }

    if (rowBelow && isSameNode(rowBelow.parentNode, nextParent)) {
      nodeBelow = rowBelow.node;
    }

    return {
      nodeBelow,
      nodeAbove,
    };
  }

  canDrop = ({
    prevPath,
    nextPath,
    nextParent,
    nextTreeIndex,
    prevTreeIndex,
    node,
  }) => {
    // doesn't move?
    if (nextTreeIndex === prevTreeIndex) {
      return false;
    }

    // is moving at root?
    if (nextParent === null) {
      return false;
    }

    // is moving to the same country language?
    const prevCountryLanguage = prevPath.find(key => key.startsWith(nodeTypes.CountryLanguage));
    const nextCountryLanguage = nextPath.find(key => key.startsWith(nodeTypes.CountryLanguage));
    if (prevCountryLanguage !== nextCountryLanguage) {
      return false;
    }

    const { nodeAbove, nodeBelow } = this.getRelativeNodes({ nextTreeIndex, nextParent });

    // doesn't allow moving a Category below an Entry
    if (nodeAbove !== null && node.type === nodeTypes.Category && nodeAbove.type === nodeTypes.Entry) {
      return false;
    }

    // doesn't allow moving an Entry above a Category
    if (nodeBelow !== null && node.type === nodeTypes.Entry && nodeBelow.type === nodeTypes.Category) {
      return false;
    }

    return true;
  }

  onMoveNode = ({
    nextParentNode,
    node,
  }) => {
    const nodeIndex = nextParentNode.children.findIndex(n => getNodeKey({ node }) === getNodeKey({ node: n }));
    let nodeAbove = nextParentNode.children[nodeIndex - 1];
    let nodeBelow = nextParentNode.children[nodeIndex + 1];

    if (nodeAbove !== undefined && node.type !== nodeAbove.type) {
      nodeAbove = null;
    }

    if (nodeBelow !== undefined && node.type !== nodeBelow.type) {
      nodeBelow = null;
    }

    const { moveNode } = this.props;
    moveNode({
      node,
      nodeAbove,
      nodeBelow,
      toParent: nextParentNode,
    });
  }

  onChange = (treeData) => {
    const { changeContentTree } = this.props;
    changeContentTree(treeData);
  }

  onVisibilityToggle = ({ node, expanded, path }) => {
    const { toggleNodeVisibility } = this.props;
    toggleNodeVisibility({ node, expanded, path });
  }

  generateNodeProps = ({ node, treeIndex }) => {
    const { currentNode } = this.props;
    const className = [node.type, `${node.type}-${node.id}`];

    // Bold node (and its ancestry) if selected
    walk({
      treeData: [node],
      getNodeKey,
      ignoreCollapsed: false,
      callback: ({ node: localNode }) => {
        if (localNode.id === currentNode.id && localNode.type === currentNode.type) {
          className.push('selected');
        }
      },
    });

    // Mark deleted node
    if (node.deleted) {
      className.push('deleted');
    }

    // Handle add link to node
    let nodeTitle = node.title;
    const nodeUrl = getNodeUrl(node);
    if (nodeUrl) {
      nodeTitle = (
        <a
          href={nodeUrl}
          tabIndex={treeIndex}
          className="text-body"
        >
          {node.title}
        </a>
      );
    }

    return {
      title: nodeTitle,
      className: className.join(' '),
    };
  }

  render() {
    const { contentTree } = this.props;
    return (
      <SortableTree
        ref={this.sortableTreeRef}
        treeData={contentTree}
        onChange={this.onChange}
        onVisibilityToggle={this.onVisibilityToggle}
        getNodeKey={getNodeKey}
        theme={FileExplorerTheme}
        canDrag={ContentTree.canDrag}
        canDrop={this.canDrop}
        canNodeHaveChildren={ContentTree.canNodeHaveChildren}
        onMoveNode={this.onMoveNode}
        generateNodeProps={this.generateNodeProps}
        isVirtualized={false}
        className="content-tree"
      />
    );
  }
}
