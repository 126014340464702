import React from 'react';
import { Provider } from 'react-redux';
import store from '@js/apps/store';
import ImageGrid from './containers/ImageGrid';

export default props => (
  <Provider store={store}>
    <ImageGrid {...props} />
  </Provider>
);
