import React from 'react';
import PropTypes from 'prop-types';
import deleteIcon from '@js/images/icon-delete.svg';

export default class ImageItem extends React.PureComponent {
  static propTypes = {
    image: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      thumbnail: PropTypes.string,
      image: PropTypes.string,
    }).isRequired,
    onClick: PropTypes.func,
    onDelete: PropTypes.func,
  };

  static defaultProps = {
    onClick: null,
    onDelete: null,
  }

  onClickItem = () => {
    const { onClick, image } = this.props;
    onClick(image);
  }

  onDelete = () => {
    const { onDelete, image } = this.props;
    onDelete(image);
  }

  render() {
    const { image, onClick, onDelete } = this.props;

    let imageElement = (
      <img
        className="asset-item__image"
        src={image.thumbnail}
        alt={image.name}
      />
    );

    if (onClick) {
      imageElement = (
        <button
          className="asset-item__button btn btn-link"
          onClick={this.onClickItem}
          type="button"
        >
          {imageElement}
        </button>
      );
    }

    return (
      <div className="asset-item">
        {imageElement}
        <div className="asset-item__name" title={image.name}>{image.name}</div>
        {onDelete && (
          <button
            type="button"
            className="asset-item__delete"
            aria-label="Delete"
            title="Delete"
            onClick={this.onDelete}
          >
            <img aria-hidden="true" src={deleteIcon} alt="" />
          </button>
        )}
      </div>
    );
  }
}
