import { connect } from 'react-redux';
import {
  fetchAssets,
  deleteAsset,
} from '../store/actions';
import ImageGrid from '../components/ImageGrid';

const mapStateToProps = (state, props) => ({
  ...props,
  images: state.imageGrid.data,
  deletingImages: state.imageGrid.deletingIDs,
  imageCount: state.imageGrid.imageCount,
  searchTerm: state.imageGrid.searchTerm,
});

const mapDispatchToProps = {
  fetchAssets,
  deleteAsset,
};

export default connect(mapStateToProps, mapDispatchToProps)(ImageGrid);
