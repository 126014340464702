import PropTypes from 'prop-types';
import nodeTypes from './node-types';

const NodeTypes = PropTypes.oneOf([
  nodeTypes.Country,
  nodeTypes.CountryLanguage,
  nodeTypes.Category,
  nodeTypes.Entry,
]);

const NodeShape = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  title: PropTypes.string.isRequired,
  editable: PropTypes.bool,
  icon: PropTypes.string,
  type: NodeTypes.isRequired,
});
NodeShape.children = PropTypes.arrayOf(PropTypes.arrayOf(NodeShape));

export { NodeTypes, NodeShape };
