import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import ImageGrid from '../containers/ImageGrid';

const appElement = document.getElementById('main');

export default class ImagePickerModal extends React.PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool,
    lang: PropTypes.shape({
      title: PropTypes.string.isRequired,
    }).isRequired,
    onPickAnAsset: PropTypes.func.isRequired,
    onRequestClose: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isOpen: false,
  };

  onPickAnAsset = (image) => {
    const { onPickAnAsset } = this.props;
    onPickAnAsset(image);
  }

  render() {
    const { lang, isOpen, onRequestClose } = this.props;
    return (
      <Modal
        contentLabel={lang.title}
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        appElement={appElement}
        closeTimeoutMS={150}
        className="container modal-content"
        overlayClassName="ReactModal__Overlay"
      >
        <div className="modal-header">
          <h5 className="modal-title">{lang.title}</h5>
          <button type="button" className="close" aria-label="Close" onClick={onRequestClose}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body image-grid">
          <ImageGrid onClickItem={this.onPickAnAsset} lang={lang} />
        </div>
      </Modal>
    );
  }
}
