import { connect } from 'react-redux';
import {
  updateSearchTerm,
} from '../store/actions';
import SearchBox from '../components/SearchBox';

const mapDispatchToProps = {
  updateSearchTerm,
};

export default connect(null, mapDispatchToProps)(SearchBox);
