import React from 'react';
import PropTypes from 'prop-types';

export default class MarkdownPreview extends React.PureComponent {
  static propTypes = {
    html: PropTypes.string.isRequired,
  };

  render() {
    const { html } = this.props;
    /* eslint-disable react/no-danger */
    return (
      <div className="mde-preview">
        <div className="mde-preview-content" dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    );
    /* eslint-enable react/no-danger */
  }
}
