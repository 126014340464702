import React from 'react';
import { Provider } from 'react-redux';
import store from '@js/apps/store';
import ImagePicker from './containers/ImagePicker';

export default props => (
  <Provider store={store}>
    <ImagePicker {...props} />
  </Provider>
);
