import React from 'react';
import PropTypes from 'prop-types';
import SortableTree, { changeNodeAtPath } from 'react-sortable-tree';
import 'react-sortable-tree/style.css';

import FileExplorerTheme from '../content-tree-theme';
import { getNodeKey } from '../helpers';
import { NodeShape } from '../node-props';
import nodeTypes from '../node-types';

export default class ContentPicker extends React.Component {
  static propTypes = {
    contentTree: PropTypes.arrayOf(NodeShape).isRequired,
    onPickANode: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      contentTree: props.contentTree,
    };
  }

  onPickANode = node => () => {
    const { onPickANode } = this.props;
    onPickANode(node);
  }

  generateNodeProps = ({ node, treeIndex }) => {
    const className = [node.type, `${node.type}-${node.id}`];

    // Mark deleted node
    if (node.deleted) {
      className.push('deleted');
    }

    // Handle add link to node
    let nodeTitle = node.title;
    if (node.type === nodeTypes.Entry || node.type === nodeTypes.Category) {
      nodeTitle = (
        <button
          type="button"
          tabIndex={treeIndex}
          className="text-body btn btn-link m-0 p-0"
          onClick={this.onPickANode(node)}
        >
          {node.title}
        </button>
      );
    }

    return {
      title: nodeTitle,
      className: className.join(' '),
    };
  }

  changeContentTree = (contentTree) => {
    this.setState({
      contentTree,
    });
  }

  toggleNodeVisibility = ({ path, expanded }) => {
    this.setState(state => ({
      contentTree: changeNodeAtPath({
        treeData: state.contentTree,
        path,
        newNode: ({ node }) => ({
          ...node,
          expanded,
        }),
        getNodeKey,
      }),
    }));
  }

  render() {
    const { contentTree } = this.state;
    return (
      <SortableTree
        treeData={contentTree}
        onChange={this.changeContentTree}
        onVisibilityToggle={this.toggleNodeVisibility}
        getNodeKey={getNodeKey}
        theme={FileExplorerTheme}
        canDrag={false}
        generateNodeProps={this.generateNodeProps}
        isVirtualized={false}
        className="content-tree"
      />
    );
  }
}
