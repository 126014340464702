import React from 'react';
import PropTypes from 'prop-types';
import ImagePickerModal from './ImagePickerModal';

export default class ImagePicker extends React.PureComponent {
  static propTypes = {
    image: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      thumbnail: PropTypes.string,
      image: PropTypes.string,
    }),
    lang: PropTypes.shape({
      placeholder: PropTypes.string.isRequired,
      pick_image: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }).isRequired,
    inputName: PropTypes.string.isRequired,
  };

  static defaultProps = {
    image: null,
  }

  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      selectedImage: props.image,
    };
  }

  openAssetPicker = () => {
    this.setState({
      isModalOpen: true,
    });
  }

  closeAssetPicker = () => {
    this.setState({
      isModalOpen: false,
    });
  }

  onPickAnAsset = (image) => {
    this.setState({
      selectedImage: image,
    });
    this.closeAssetPicker();
  }

  render() {
    const { lang, inputName } = this.props;
    const { selectedImage, isModalOpen } = this.state;
    return (
      <React.Fragment>
        {selectedImage != null ? <input name={inputName} type="hidden" value={selectedImage.id} /> : null}
        <div className="input-group mb-3">
          <input
            value={selectedImage ? selectedImage.image : ''}
            type="text"
            className="form-control"
            placeholder={lang.placeholder}
            aria-label={lang.placeholder}
            disabled
          />
          <div className="input-group-append">
            <button
              onClick={this.openAssetPicker}
              className="btn btn-outline-secondary"
              type="button"
            >
              {lang.pick_image}
            </button>
          </div>
        </div>
        <ImagePickerModal
          isOpen={isModalOpen}
          onPickAnAsset={this.onPickAnAsset}
          onRequestClose={this.closeAssetPicker}
          lang={lang}
        />
      </React.Fragment>
    );
  }
}
