import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

export default class SearchBox extends React.PureComponent {
  static propTypes = {
    placeholder: PropTypes.string.isRequired,
    defaultSearchTerm: PropTypes.string,
    updateSearchTerm: PropTypes.func.isRequired,
  };

  static defaultProps = {
    defaultSearchTerm: '',
  }

  constructor(props) {
    super(props);

    this.state = {
      searchTerm: props.defaultSearchTerm,
    };
  }

  componentDidUpdate(prevProps) {
    const { defaultSearchTerm } = this.props;

    if (defaultSearchTerm === prevProps.defaultSearchTerm) {
      return;
    }

    // eslint-disable-next-line react/no-did-update-set-state
    this.setState({
      searchTerm: defaultSearchTerm,
    });
  }

  onChange = (event) => {
    this.setState({
      searchTerm: event.target.value,
    });
  };

  onSubmitSearch = (e) => {
    e.preventDefault();
    const { searchTerm } = this.state;
    const { updateSearchTerm } = this.props;
    updateSearchTerm(searchTerm);
  }

  render() {
    const { placeholder } = this.props;
    const { searchTerm } = this.state;
    return (
      <form onSubmit={this.onSubmitSearch}>
        <div className="input-group mb-3">
          <input
            value={searchTerm}
            onChange={this.onChange}
            type="text"
            className="form-control"
            placeholder={placeholder}
            aria-label={placeholder}
          />
          <div className="input-group-append">
            <button type="submit" className="btn btn-outline-secondary" title="Search">
              <FontAwesomeIcon icon={faSearch} />
            </button>
          </div>
        </div>
      </form>
    );
  }
}
