import ReactDOM from 'react-dom';
import React from 'react';
import { Provider } from 'react-redux';
import store from '@js/apps/store';
import { expandContentTree } from './store/actions';
import App from './containers/App';

const AppRoot = () => (
  <Provider store={store}>
    <App />
  </Provider>
);

document.addEventListener('turbolinks:load', () => {
  const container = document.getElementById('content-tree');
  if (container && !container.dataset.mounted) {
    ReactDOM.render(React.createElement(AppRoot), container);
    container.dataset.mounted = true;
  }
});

document.addEventListener('turbolinks:before-render', (e) => {
  const container = document.getElementById('content-tree');
  if (!container) {
    return;
  }

  const { newBody } = e.data;
  const newNodes = newBody.querySelectorAll('#content-tree');
  if (newNodes.length === 0) {
    ReactDOM.unmountComponentAtNode(container);
    delete container.dataset.mounted;
  }
});

// For rspec to test content tree
if (process.env.RAILS_ENV !== 'production') {
  window.expandContentTree = () => {
    store.dispatch(expandContentTree());
  };
}
