import { changeNodeAtPath } from 'react-sortable-tree';

import {
  LOAD_CONTENT_TREE_DATA,
  UPDATE_CONTENT_TREE_DATA,
  CHANGE_CONTENT_TREE_DATA,
  UPDATE_CURRENT_NODE_SELECTION,
  INVALIDATE_CONTENT_TREE_DATA,
  TOGGLE_NODE_VISIBILITY,
} from './actions';
import { getNodeKey } from '../helpers';

const initState = {
  lastRequest: Date.now(),
  didInvalidate: true,
  currentNode: {
    type: null,
    id: null,
  },
  data: [],
};


export default (state = initState, action) => {
  switch (action.type) {
    case LOAD_CONTENT_TREE_DATA:
      return {
        ...state,
        didInvalidate: false,
        lastRequest: Date.now(),
        data: action.data,
      };
    case CHANGE_CONTENT_TREE_DATA:
    case UPDATE_CONTENT_TREE_DATA:
      return {
        ...state,
        data: action.data,
      };
    case INVALIDATE_CONTENT_TREE_DATA:
      return {
        ...state,
        didInvalidate: true,
      };
    case TOGGLE_NODE_VISIBILITY: {
      const { path, expanded } = action.data;
      return {
        ...state,
        data: changeNodeAtPath({
          treeData: state.data,
          path,
          newNode: ({ node }) => ({
            ...node,
            expanded,
          }),
          getNodeKey,
        }),
      };
    }
    case UPDATE_CURRENT_NODE_SELECTION:
      return {
        ...state,
        currentNode: action.data,
      };

    default:
      return state;
  }
};
