import {
  faHeading,
  faBold,
  faItalic,
  faStrikethrough,
  faExternalLinkAlt,
  faLink,
  faQuoteRight,
  faCode,
  faImage,
  faListUl,
  faListOl,
  faTasks,
} from '@fortawesome/free-solid-svg-icons';

export default {
  heading: faHeading,
  bold: faBold,
  italic: faItalic,
  strikethrough: faStrikethrough,
  link: faExternalLinkAlt,
  'quote-right': faQuoteRight,
  code: faCode,
  image: faImage,
  'list-ul': faListUl,
  'list-ol': faListOl,
  tasks: faTasks,
  entry_link: faLink,
};
