import {
  faFile,
  faFileVideo,
  faBoxOpen,
} from '@fortawesome/free-solid-svg-icons';

export default {
  content: faFile,
  product: faBoxOpen,
  video: faFileVideo,
};
