import { connect } from 'react-redux';
import {
  fetchContentTreeIfNeeded,
  updateCurrentNodeSelection,
  invalidateContentTree,
} from '../store/actions';
import App from '../components/App';

const mapDispatchToProps = {
  fetchContentTreeIfNeeded,
  updateCurrentNodeSelection,
  invalidateContentTree,
};

export default connect(null, mapDispatchToProps)(App);
